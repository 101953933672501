import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import LODINGIMG from './../../assets/images/IBRAInc-logo.png';
import { API_URL, API_BASE_URL } from './../../config/config';
import axios from 'axios';
import { SITE_URL, HEADER_MENU } from './../../config/config';
import { compose } from 'redux';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { withRouter } from 'react-router';
const { Option } = Select;

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menus: [],
			loading: true,
			homeInnerClass: 'header_main ',
			object_id: 'home',
			mobileMenu: false,
			clicked: [],
			menuShow: 0,
			searchText: '',
			searchValue: '',
			data: [],
			value: undefined,
			path: false
		};
		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		// this.onChangeSearch = this.searchHandleOnChange.bind(this);
	}

	componentWillMount() {
		document.addEventListener('click', this.handleOutsideClick, false);

	}

	componentWillReceiveProps() {
		console.log("path", this.props.history.location.pathname)
		if (this.props.history.location.pathname == '/search_details' || this.props.history.location.pathname == '/no_result' || this.props.history.location.pathname == '/privacy-policy' || this.props.history.location.pathname == '/terms-use') {
			var path = this.props.history.location.pathname;

		}
		this.setState({ path: path })
	}
	componentWillUnmount() {
		document.removeEventListener('click', this.handleOutsideClick, false);
	}

	handleClick(index, e) {
		let menuShow = this.state.menuShow;
		if (menuShow == 0) {
			let clicked = this.state.clicked;
			clicked[index] = !clicked[index];
			this.setState({ clicked: clicked });
			//console.log(e.currentTarget.classList.contains('menu-item-has-children'), 'classsss');
			if (!e.currentTarget.classList.contains('menu-item-has-children')) {
				this.setState({ mobileMenu: !this.state.mobileMenu });
			}
			this.setState({ 'menuShow': 1 })
		} else {
			this.setState({
				clicked: []
			});
			this.setState({ 'menuShow': 0 })
			if (!e.currentTarget.classList.contains('menu-item-has-children')) {
				this.setState({ mobileMenu: !this.state.mobileMenu });
			}
		}
		//console.log("clickkkkk=> ", menuShow)
	}

	handleOutsideClick(event) {
		//	if (!this.refs.megaMenu.contains(event.target)) {

		this.setState({
			clicked: []
		});
		// this.setState({'menuShow':0})

		// text.classList.add("sub-menu false");
		//	}
	}


	componentDidMount() {
		var data = {
			menu_id: HEADER_MENU
		};
		this.props.headerData(data, (response) => {
			let { data } = response;

			this.setState({ menus: data && data.length >= 0 ? data : null });

		});

		// var data = {
		//   menu_id: FOOTER_MENU_1
		// };
		// this.props.headerData(data, response => {
		//   //let { menu_1 } = response;
		//   this.setState({ menu_1: response.data });
		//   //console.log("footer menu 1", this.state.menu_1);
		// });
		// this.props.generalData(data1, response => {
		//   this.setState({ themeSetting: response.data.acf });
		//   //console.log("themeSetting", this.state.themeSetting);
		// });
	}

	handleMenuClick() {
		this.setState({
			mobileMenu: !this.state.mobileMenu
		});
		// var text = document.getElementById('test');
		// console.log("text",text)
		// text.classList.remove("sub-menu");
	}

	onBlankClick = (e) => {
		e.preventDefault();

	};



	searchHandleOnChange = async (value) => {
		console.log("text11", value)
		let self = this;
		let values;
		let { searchText } = this.state;
		searchText = value;
		this.setState({ searchText: value })
		let APIURL = API_URL + `/filter-api/${searchText}?_format=json`;

		axios({
			method: 'get',
			url: APIURL,
			headers: {
				'Content-Type': 'application/json',
				// Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
			}
		})
			.then(function (response) {
				if (response.status == 200) {
					values = response.data ? response.data : '';
					self.setState({
						searchValue: [values],
					});
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	openSearch = () => {
		console.log("kkk", document.body.classList)
		document.body.classList.add('detail_search_body');
		this.setState({
			isOpensearch: true
		});
	}

	closeSearch = () => {
		document.body.classList.remove("detail_search_body");
		// document.body.classList.remove('detail_search_body');
		this.setState({
			isOpensearch: false
		});
	}

	handleChange = value => {
		this.setState({ value });
	};

	optionSelected = (data) => {
		console.log("1234", data)
		document.body.classList.remove("detail_search_body");
		this.props.history.push({ pathname: data.replace('blog', 'blogs'), view_node: data, searchData: this.state.searchValue, name: this.state.searchText });
		// this.props.history.push(pathname:{})
		this.state.searchText = '';
		this.setState({ isOpensearch: false })
	}


	onInputKeyDown = (event) => {
		//   console.log("testi1234",this.state.searchValue[0])
		if (event.keyCode == 13) {
			if (this.state.searchValue[0] == '') {
				document.body.classList.remove("detail_search_body");
				this.props.history.push('/no_result')
				this.closeSearch();
				// document.body.className.replace("detail_search_body","");
			} else if (this.state.searchValue.length > 0) {
				document.body.classList.remove("detail_search_body");
				this.props.history.push({ pathname: '/search_details', searchData: this.state.searchValue, name: this.state.searchText });

				this.closeSearch();
				// document.body.className.replace("detail_search_body","");
				this.state.searchText = '';
			}
		}

	}

	render() {
		var { searchText, isOpensearch, searchValue } = this.state;
		let { menus } = this.state;
		const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>);
		// document.removeEventListener('click', this.handleOutsideClick, false);
		console.log("$$", searchValue)
		return (
			<header>
				<div className='header-top'>
					<div className='container'>
						<div className='header-top-inner'>
							<div className='serchform'>
								<Link to='#' className='search_button' onClick={this.openSearch} />
								{/* { <input id="search" placeholder="Search Details"  className='form-control'  type="text" value={searchText}  onChange={this.searchHandleOnChange.bind(this)} maxLength = "35"/>   */}
							</div>
							<div className={isOpensearch ? "search-wrap open " : "search-wrap"}>
								<form>
									<div className="input-group">

										{/* <input type="text"  id="search"  className="form-control" type="text" value={searchText}  onChange={this.searchHandleOnChange.bind(this)} maxLength = "35"  placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" /> */}
										<div className="search-box">
											<button className="search-icon"><span class="fa fa-search" aria-hidden="true"></span></button>

											<React.Fragment>
												<Select
													showSearch
													value={searchText}
													style={this.props.style}
													defaultActiveFirstOption={false}
													showArrow={false}
													filterOption={false}
													onSearch={this.searchHandleOnChange}
													notFoundContent={null}
													onSelect={this.optionSelected}
													onInputKeyDown={this.onInputKeyDown}

												>
													{searchValue && searchValue.map((search) => (
														Object.values(search).map((data, i) => {

															return <Option key={data.alias} >

																{data.node_title}
															</Option>
														})
													))}
												</Select>
											</React.Fragment>
											{/* {searchValue && searchValue.map((search)=>(	
									 	
									 <Link
										 to={{
											 pathname:'/searchdetails',
											 state: {
												 view_node: search.read_more,
											 }
										 }} 
										 url={search.read_more}
										 className='defaultlink link-yellow with-arrow'>
										{search.node_title}	
									 </Link>	
										
									))} */}
											{/* <div className="input-group-append">
											<button className="fa fa-search" type="button"></button>
										</div> */}
											<a href="javacript:;" className="close-button"><span className="fa fa-times" aria-hidden="true" onClick={this.closeSearch.bind(this)}></span></a>
										</div>
									</div>
								</form>
							</div>
							<ul className='login_track_link'>
								<li>
									<a href='https://www.ibrainconline.com/clientportal' target='_blank'>
										LOGIN
									</a>
								</li>
								<li>
									<Link
										to={{
											pathname: '/track-your-package',
											state: {
												object_id: '/track-your-package'
											}
										}}
									>
										Track your package
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className={this.state.path ? 'Header-main search-body' : "Header-main "}>

					<div className='container'>
						<div className='header-main-inner'>
							<div className='logo'>
								<Link
									to={{
										pathname: '/',
										state: {
											object_id: 'home'
										}
									}}
								>
									<img src={LODINGIMG} />
								</Link>
							</div>
							<div className='main_nmeu_wpr'>
								<div
									id='nav-icon2'
									className={this.state.mobileMenu ? 'mobile-main-menu open' : 'mobile-main-menu'}
									onClick={this.handleMenuClick}
								>
									<span />
									<span />
									<span />
									<span />
									<span />
									<span />
								</div>
								<ul
									className={
										this.state.mobileMenu ? (
											'd-flex flex-column flex-lg-row  main_menu toggled'
										) : (
												'd-flex flex-column flex-lg-row  main_menu'
											)
									}
								>
									{menus && menus.length >= 0 && Array.isArray(menus) && menus.map((page, i) => (

										<li
											key={i}
											// onClick={() =>
											// 	this.setState({
											// 		mobileMenu: !this.state.mobileMenu
											// 		//submenu: page.alias
											// 	})}
											onClick={this.handleClick.bind(this, i)}

											className={page.sub_menu ? 'menu-item menu-item-has-children' : 'menu-item'}

										>
											{page.sub_menu ? (
												<a href='' onClick={this.onBlankClick} ref='submegaMenu'>
													{page.menu_name}
												</a>
											) : (
													<Link
														to={{
															pathname: '' + page.link,
															state: {
																object_id: page.link ? page.link : 'home'
															}
														}}
													>
														{page.menu_name}
													</Link>
												)}
											<ul
												className={page.sub_menu ? 'sub-menu' + ' ' + this.state.clicked[i] : ''}
												ref='megaMenu'
												id="test"
											>
												{page.sub_menu &&
													page.sub_menu.map((item, i) => (
														<li
															key={i}
															// onClick={() =>
															// 	this.setState({
															// 		clicked: []
															// 	})}
															onClick={this.handleMenuClick}
															className='sub-item'
														>
															<Link
																to={{
																	pathname: '' + item.link,
																	state: {
																		object_id: item.link
																	}
																}}
															>
																{item.menu_name}
															</Link>
														</li>
													))}
											</ul>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	}
}

// export default Header;
const mapStateToProps = (state) => ({
	headerdata: state.headerdata.headerDetails
	// permissionsList: state.admindata.rolePermissions,
});
export default withRouter(connect(mapStateToProps, actions)(Header));
