import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Layout from './components/Layout';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/combined.css';
//import './assets/js/bootstrap.min.js';
import './assets/js/custom.js';
import { store, persistor } from './store';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Provider store={store}>
				<Layout />
			</Provider>
		);
	}
}

export default App;
