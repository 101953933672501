import React, { Component } from "react";
import axios from 'axios';
import swal from 'sweetalert';
import { API_URL } from './../../config/config';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Select from 'react-select';
import Spinner from './../../assets/images/spinner.gif';
//import { Switch } from 'react-router-dom';
import $ from 'jquery'
const emailRegex = RegExp(
	/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const MobileRegex = RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);

const formValid = ({ formErrors, ...rest }) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach(val => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(rest).forEach(val => {
		val === null && (valid = false);
	});

	return valid;
};

const industryoptions = [
	{ value: '', label: 'Your Industry' },
	{ value: 'B2B', label: 'B2B' },
	{ value: 'Hi Tech', label: 'Hi Tech' },
	{ value: 'Medical', label: 'Medical' }
];
class Contact_us_module extends Component {
	constructor(props) {
		super(props);
		this.state = {
			yourName: null,
			companyName: null,
			yourEmail: null,
			yourContact: null,
			yourIndustry: null,
			yourMessage: null,
			formErrors: {
				yourName: "",
				companyName: "",
				yourEmail: "",
				yourContact: "",
				yourIndustry: '',
				yourMessage: ""
			}
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}
	handleSubmit = e => {
		e.preventDefault();
		//console.log('this.state',this.state)
		$('.fromspinner').removeClass('d-none')
		if (formValid(this.state)) {
			let body = {
				webform_id: 'contact_form',
				name: this.state.yourName,
				company: this.state.yourEmail,
				email_address: this.state.yourEmail,
				mobile_number: this.state.yourContact,
				your_company: this.state.yourIndustry.label,
				message: this.state.yourMessage
			};
			console.log('json=>', JSON.stringify(body));
			axios({
				method: 'post',
				url: API_URL + '/webform_rest/submit?_format=json',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
				},
				data: body
			}).then((response) => {
				let { data } = response;
				console.log('form submit responce', data.sid);
				if (data.sid !== '') {
					swal("Thank you for getting in touch, We will get back to you shortly.")
						.then((value) => {
							//document.getElementById("RequestQuoteFrom").reset();
							window.location.reload();
						});
				} else {
					swal('Something went wrong, please try again after sometime', '', 'error');
				}
				$('.fromspinner').addClass('d-none')
			});
		} else {
			console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
			swal('Please fill all the require fields before submit a form', '', 'error');
			$('.fromspinner').addClass('d-none')
		}
	}


	handleChange = (e) => {
		e.preventDefault();
		//this.setState({ [e.target.name]: e.target.value });
		const { name, value } = e.target;
		//console.log(e.target,'target');
		let formErrors = { ...this.state.formErrors };
		//let formErrors = this.state.formErrors; 

		switch (name) {
			case "yourName":
				formErrors.yourName =
					value.length < 3 ? "minimum 3 characaters required" : "";
				break;
			case "companyName":
				formErrors.companyName =
					value.length < 3 ? "minimum 3 characaters required" : "";
				break;
			case "yourEmail":
				formErrors.yourEmail = value.length <= 0 ? "email is required" : (formErrors.yourEmail = emailRegex.test(value) ? "" : "invalid email address");
				break;
			case "yourMobile":
				formErrors.yourMobile = value.length <= 0 ? "mobile number required" : (formErrors.yourMobile = MobileRegex.test(value) ? "" : "invalid mobile number");
				break;
			case "yourPhone":
				formErrors.yourPhone =
					value.length <= 0 ? "phone number required" : (formErrors.yourPhone = MobileRegex.test(value) ? "" : "invalid phone number");
				break;
			case "yourMessage":
				formErrors.yourMessage =
					value.length < 6 ? "minimum 6 characaters required" : "";
				break;
			default:
				break;
		}

		this.setState({ formErrors, [name]: value }, () => console.log(this.state));
	};

	handleSelect = yourIndustry => {
		this.setState({ yourIndustry });
		let formErrors = { ...this.state.formErrors };
		formErrors.yourIndustry = yourIndustry.value === '' ? "please select an item in the list" : "";;
		this.setState({ formErrors }, () => console.log(this.state));
	};

	render() {
		//console.log(this.state);
		//let { yourName, yourEmail, yourMobile, yourPhone } = this.state;
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			//console.log('myobject', banner_image);		;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			const { yourIndustry } = this.state;
			const { formErrors } = this.state;
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area request_quote_module'>
							{obj.field_contact_heading && (
								<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content row'>
									<div className='left-part-content col-lg-5 col-md-12'>
										<div className="contactinfo">
											<ul className="contentinfo_list">
												{/* <li className="address">
													<h4>{obj.field_contact_address_name.value?obj.field_contact_address_name.value:'Address'}</h4>
													{obj.field_contact_address_detail.value && (
														ReactHtmlParser(obj.field_contact_address_detail.value)
													)}														
												</li> */}
												<li className="telephone">
													<h4>{obj.field_telephone_heding.value ? obj.field_telephone_heding.value : 'Telephone'}</h4>
													{obj.field_telephone_detail.value && (
														ReactHtmlParser(obj.field_telephone_detail.value)
													)}
												</li>
												<li className="email">
													<h4>Email</h4>
													{obj.field_contact_email.value && (
														ReactHtmlParser(obj.field_contact_email.value)
													)}
												</li>
											</ul>
										</div>
									</div>
									<div className='right-part-content col-lg-7 col-md-12'>
										<div className='form_section'>
											{ReactHtmlParser(obj.field_contact_heading.value)}
											<form
												id='myContactFrom'
												className='cv_form_inner'
												onSubmit={this.handleSubmit} noValidate>
												<div className='form-group row'>
													<div className='yourName form-item col-md-6 col-12'>
														<input
															type='text'
															className={formErrors.yourName.length > 0 ? 'form-control error ' : 'form-control'}
															name='yourName'
															placeholder='Your Name'
															//onChange={this.handleChange}
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourName.length > 0 && (
															<span className="errorMessage">{formErrors.yourName}</span>
														)}
													</div>
													<div className='companyName form-item col-md-6 col-12'>
														<input
															type='text'
															className={formErrors.companyName.length > 0 ? 'form-control error ' : 'form-control'}
															name='companyName'
															placeholder='Company'
															//onChange={this.handleChange}
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.companyName.length > 0 && (
															<span className="errorMessage">{formErrors.companyName}</span>
														)}
													</div>

												</div>
												<div className='form-group row'>
													<div className='yourEmail form-item col-md-6 col-12'>
														<input
															type='email'
															className={formErrors.yourEmail.length > 0 ? 'form-control error ' : 'form-control'}
															name='yourEmail'
															placeholder='Email'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourEmail.length > 0 && (
															<span className="errorMessage">{formErrors.yourEmail}</span>
														)}
													</div>

													<div className='yourContact form-item col-md-6 col-12'>
														<input
															type='text'
															className={formErrors.yourContact.length > 0 ? 'form-control error ' : 'form-control'}
															name='yourContact'
															placeholder='Phone'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourContact.length > 0 && (
															<span className="errorMessage">{formErrors.yourContact}</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourIndustry form-item col-12'>
														<Select
															name='yourIndustry'
															className={formErrors.yourIndustry.length > 0 ? 'selectbox error ' : 'selectbox'}
															placeholder='Type of freight'
															value={yourIndustry}
															onChange={this.handleSelect}
															options={industryoptions}
															noValidate
														/>
														{formErrors.yourIndustry.length > 0 && (
															<span className="errorMessage">{formErrors.yourIndustry}</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourMessage form-item col-12'>
														<textarea
															className={formErrors.yourMessage.length > 0 ? 'form-control error ' : 'form-control'}
															name='yourMessage'
															placeholder='Message'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourMessage.length > 0 && (
															<span className="errorMessage">{formErrors.yourMessage}</span>
														)}
													</div>
												</div>

												<button type="submit" className="defaultbtn btn-yellow">Submit</button>
												<img src={Spinner} className="fromspinner d-none" height="50" width="50" />
											</form>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="conatct_track_and_map_section">
						<div className='container'>
							{obj.field_contact_track_your_package && (<h2 className="heading-main">{obj.field_contact_track_your_package.value}</h2>)}
							<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content row'>
								<div className='left-part-content image-wpr col-lg-8 col-md-12'>
									{obj.field_contact_map_image && (
										<img
											src={obj.field_contact_map_image.url}
											alt={obj.field_contact_map_image.alt}
											title={obj.field_contact_map_image.title}
										/>
									)}
								</div>
								<div className='left-part-content col-lg-4 col-md-12'>
									<div className="col-12 track_package contact-box">
										{obj.field_contact_track_package_name && (<h4>{obj.field_contact_track_package_name.value}</h4>)}
										{obj.field_track_package_detail && <div className="packinfo">{ReactHtmlParser(obj.field_track_package_detail.value)}</div>}
										{obj.field_contact_track_link && (
											<Link
												to={{
													pathname: '/' + obj.field_contact_track_link.uri.replace('internal:/', ''),
													state: {
														object_id: obj.field_contact_track_link.uri.replace('internal:/', '')
													}
												}}
												className="packagelink"
											></Link>
										)}
									</div>
									<div className="col-12 career_package contact-box">
										{obj.field_contact_career_name && (<h4>{obj.field_contact_career_name.value}</h4>)}
										{obj.field_contact_career_detail && <div className="packinfo">{ReactHtmlParser(obj.field_contact_career_detail.value)}</div>}
										{obj.field_contact_career_link && (
											<Link
												to={{
													pathname: '/' + obj.field_contact_career_link.uri.replace('internal:/', ''),
													state: {
														object_id: obj.field_contact_career_link.uri.replace('internal:/', '')
													}
												}}
												className="packagelink"
											></Link>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Contact_us_module;
