import React, { Component } from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class Terms_use extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        let obj = this.props.moduledata ? this.props.moduledata : null;
        if (obj !== null) {
            //let content = obj.field_background_banner_image ? obj.field_background_banner_image : null;
            // obj.field_terms_and_conditions && console.log("content", obj.field_terms_and_conditions.value)
            return (
                <div className='page_innter_container'>
                    <div className="container">
                        <div className="search-blog">
                            <div className="search-text">

                            </div>
                            <div className="search-results">
                                <h2>Terms and Conditions</h2>
                            </div>
                            <div className="serch-form">
                                {obj.field_terms_and_conditions && ReactHtmlParser(obj.field_terms_and_conditions.value)}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Terms_use;