import axios from 'axios';
import { HEADER, GENERAL_DATA } from './types';
import { API_URL, API_URL2 } from './../config/config';

export const headerData = (body, callback) => async (dispatch) => {
	//console.log("api", API_URL + "wp-json/menus/v1/menus/" + body.menu_id);
	// console.log('api134', API_URL + '/' + body.menu_id);

	try {
		let response = await axios({
			method: 'get',
			// url: API_URL + '/api/menu_items/' + body.menu_id,
			 url: API_URL + '/' + body.menu_id,
			// url: 'https://ibraincapi.devpress.net/home-page-header-menu?_format=json',
			
			headers: {
				'Content-Type': 'application/json',
				// Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
			}
		});

		let { data } = response;
		
		if (data) {
			let payload = { data: response };
			dispatch({ type: HEADER, payload });
		}
		callback(response);
	} catch (error) {
		throw error;
	}
};

export const generalData = (body, callback) => async (dispatch) => {
	try {
		let response = await axios({
			method: 'get',
			url: API_URL + '/footer-section?_format=json',
			// headers: {
			// 	'Content-Type': 'application/x-www-form-urlencoded'
			// }
			headers: {
				'Content-Type': 'application/json',
				//Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
			}
		});
		let { data } = response;
		if (data) {
			let payload = { data: response };
			dispatch({ type: GENERAL_DATA, payload });
		}

		callback(response);
	} catch (error) {
		throw error;
	}
};
