import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class About_us_module extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount(){
		window.scrollTo(0, 0)
	}
	
	render() {
		//console.log(this.state);
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			let about_us_heading = obj.field_about_us_heading ? obj.field_about_us_heading : null;
			let about_us_details = obj.field_about_us_details ? obj.field_about_us_details : null;
			//console.log('myobject', banner_image);		;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area'>
							<div className='about_us_conaten'>
								{about_us_heading !== null && about_us_heading.value ? (
									ReactHtmlParser('<p><b>' + about_us_heading.value + '</b></p>')
								) : (
									''
								)}
								{about_us_details !== null && about_us_details.value ? (
									ReactHtmlParser('<p>' + about_us_details.value + '</p>')
								) : (
									''
								)}
								{obj.field_image && (
									<div className='full_image'>
										<img
											src={obj.field_image.url}
											alt={obj.field_image.alt}
											title={obj.field_image.title}
										/>
									</div>
								)}
								{obj.field_about_us_left_details || obj.field_about_ibra_right_image ? (
									<div className='d-flex justify-content-between align-items-center
                                    flex-column flex-lg-row two-part-content no-gutters'>
										<div className='left-part-content col-lg-5 col-md-12'>
											{ReactHtmlParser(obj.field_about_us_left_details.value)}
										</div>
										<div className='right-part-content image-wpr col-lg-6 col-md-12'>
											<img
												src={obj.field_about_ibra_right_image.url}
												alt={obj.field_about_ibra_right_image.alt}
												title={obj.field_about_ibra_right_image.title}
											/>
										</div>
									</div>
								) : (
									''
								)}
								{obj.field_about_us_bottom_content && (
									<div className='about_bottom_content'>
										{ReactHtmlParser(obj.field_about_us_bottom_content.value)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default About_us_module;
