import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Infinitescroll from './infinitescroll';
import BlogCategory from './blog_category_list';
class Blog_module extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			//console.log('myobject', banner_image);		
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			return ( 
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area blog_section'>
							<div className='blog_content d-flex justify-content-between flex-column flex-lg-row two-part-content row'>
								<div className='blog_left left-part-content col-lg-9 col-md-12'>
									<Infinitescroll />
								</div>
								<div className='blog_right left-part-content col-lg-3 col-md-12'>
									<BlogCategory />
									{obj.field_blog_offer_image && obj.field_blog_offer_image.url ? (
										<div className='offerwpr'>
											<img
												src={obj.field_blog_offer_image.url}
												alt={obj.field_blog_offer_image.alt}
												title={obj.field_blog_offer_image.title}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Blog_module;
