import React, { Component } from 'react';
class Privacy_policy extends Component {
    constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}
    render() { 
        return (
            <div className='page_innter_container'>
            <div className="container">
                <div className="search-blog">
                    <div className="search-text">
                      
                    </div>
                    <div className="search-results">
                        <h2>Privacy and Policy</h2>
                        
                    </div>
                    <div className="serch-form">
                      
                    </div>
                </div>
            </div>
        </div>
          );
    }
}
 
export default Privacy_policy;