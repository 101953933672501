import React, { Component } from 'react';
import Header from './common/Header';
import Footer from './common/Footer';
import Contentpart from './Contentpart';
import { BrowserRouter as Router, Route, Link ,withRouter} from 'react-router-dom';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Router>
				<Header />
				<Route path='/' exact className='active_home' component={Contentpart} />
				<Route path='/service/:slug' exact component={Contentpart} />
				<Route path='/technology/:slug' exact component={Contentpart} />
				<Route path='/blog/:slug' exact component={Contentpart} />
				<Route path='/blogs/:slug' exact component={Contentpart} />
				<Route path='/:slug' exact component={Contentpart} />
				{/* <Route path='/searchdetails/:slug' exact component={Contentpart} /> */}
				<Footer />
			</Router>
		); 
	}
}

export default Layout;
