import React, { Component } from 'react';
import { API_URL } from './../../config/config';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Search_details extends  React.Component {
    constructor(props) {
		super(props);
		this.state = {};
	}

    render() { 
        let  url1  =  this.props.getPropsData.location.searchData;
        let name = this.props.getPropsData.location.name
        
        let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
        return (
            <div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
           
                <div className="container">
                    <div className="search-blog">
                        
                        <div className="search-text">
                            <h2>Search Details : {name}</h2>
                        </div>
                        { url1 && Array.isArray( url1) && url1.map((search) => {
                            return Object.values(search).map((data,i)=>{
                                return(
                                   
                                    <div className="search-title" key={i}>
                                        <h5><a>{data.node_title}</a></h5>
                                        <h6><a>{data.page_type}</a></h6>
                                        
                                        { data.page_type == 'blog' ?
                                       
                                        <Link
                                            to={{
                                            
                                                pathname:data.alias.replace('blog', 'blogs'),
                                              
                                                    view_node: data.alias,
                                               
                                        }}
                                        className='defaultbtn'
									   >
										Read More </Link>
                                        :<a href={data.alias} className="defaultbtn">Read More</a>
                                     }
                                    </div>
                                )
                            })
                        })
                        }
                    </div>
                </div>
            </div>
          );
       }
    }
}
 
export default Search_details;