import React from 'react';
import axios from 'axios';
import Select from 'react-select'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL, API_BASE_URL } from './../../config/config';
import { compose } from 'redux';

const  queryString  =  require('query-string')
console.log(API_BASE_URL);
class Infinitescroll extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//object_id: '/blog',
			data: [],
			// per: 6,
			// page: 0,
			last_page: false,
			total_pages: null,
			st:'All',
			current_page: 0,
			per_page:2,  
			total : 1
		};
	}

	loadUser = () => {
		var pathname = window.location.pathname; 	
		var  st  =  pathname.substr(6, 7);
		const { data ,per_page,current_page} = this.state;
		let self = this;
		let values, mykey;
		let tid = this.state.tid;
		
		let APIURL = API_URL + `/blog-list-page-api?_format=json&tid=${st?st:"All"}&page=${current_page}`;
		// let APIURL = API_URL + `/blog-list-page-api?_format=json`;
		
		axios({
			method: 'get',
			url: APIURL,
			headers: {
				'Content-Type': 'application/json',
				// Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
			}
		})
			.then(function(response) {
			
				if (response.status == 200) {
					values = response.data ? response.data : '';
					self.setState({
						// ...data
						data:[ values ],
						per_page:response.data.per_page,
						total:response.data.count_blog_data,
						current_page:response.data.current_page,
						scrolling: false
					});
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	};
	
	// loadMore = () => {
	// 	this.setState(
	// 		(prevState) => ({
	// 			page: prevState.page + 1,
	// 			scrolling: false
	// 		}),
	// 		this.loadUser
	// 	);
	// };

	componentWillMount() {
		this.loadUser();
	}


	//*********** PAGINATION ****************************************************//

	paginationChange(current_page, per_page) {
		current_page = parseInt(current_page) - 1;
		this.setState({
		  current_page, per_page
		}, () => this.loadUser());
	} 
	
	componentDidMount(){
		 let  url1  =  this.props;
		//  const parsed = queryString.parse(location.search);
         var  params  =  queryString.parse(url1);
	}

	render() {
		var { total, current_page, per_page ,data} = this.state;
		const MAX_LENGTH = 250;
		
		return (
			<div>
				<ul className='bloglisting'>	
					{ this.state.data.length > 0 && this.state.data && this.state.data.map((user) => (
						
						Object.values(user.blog_page !==undefined && user.blog_page !==null && user.blog_page ).map((data,i)=>{
							 return (
								// blog_nid
								<li key={data.base_url_read_more} className='row blogitem'>
							   <div className='blogimg col-md-3 col-sm-12'>
							  	
								   <img src={data.blog_front_image} />
							   </div>
							   <div className='bloginfo col-md-9 col-sm-12'>
								   <p className='blogtitle'>{data.blog_name}</p>
								   <p className='bloddate'>{data.blog_date}</p>
								 <div className='short_info'>
									   {ReactHtmlParser(`${data.blog_body_section.substring(0, MAX_LENGTH)}...`)}
								   </div> 
								   {console.log("ll",data.alias.split('/').pop())}
								   <Link
									   to={{
									
									      pathname:'/blogs/'+data.alias.split('/').pop(),
										//    state: {
											   view_node: data.alias,
										    // }
									   }} 
									//    view_node={data.view_node}
									   className='defaultlink link-yellow with-arrow'>
									   Read More	
								   </Link>	
								   {/* <Link												
										to={{
											pathname:
												'/' +
												obj.home_page_about_intro.about_ibra_readmore_link.replace(
													'internal:/',
													''
												),
											state: {
												object_id: obj.home_page_about_intro.about_ibra_readmore_link.replace(
													'internal:/',
													''
												)
											}
										}}
										className='defaultbtn btn-yellow'
									>
										{obj.home_page_about_intro.about_ibra_readmore_title}
									</Link> */}
							   </div>
						   </li>
						   )
						})
						
					))}
				</ul>
				{/* {this.state.last_page == false && (
					<div className='btnwpr load_more_wpr'>
						<button
							onClick={(e) => {
								this.loadMore();
							}}
							className='defaultbtn yellowbtn'
						>
							Load More
						</button>
					</div>
				)} */}
				
				{
				//  (this.state.data && this.state.data.length === 0) ? <div></div> :
                <div className="row table-footer text-right" >
				  <Pagination className="custom-pagination mt-4 pl-4" pageSize={per_page} current={parseInt(current_page)+1} total={total} onChange={this.paginationChange.bind(this)}  />
                </div>
				}
			</div>
		);
	}
}
export default Infinitescroll;


