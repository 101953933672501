import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL } from '../../config/config';
class BlogCategory extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			//object_id: '/blog',
			data: [],
			per: 2,
			page: 0,
			tid: 'All',
			last_page: false,
			total_pages: null
		};
	}
	loadCategory = () => {
		//if (this.state.object_id) {
		const { data } = this.state;
		let values;
		let self = this; //Once inside the axios request, this will not be the same this.
		let APIURL = API_URL + `/blog-topics-list?_format=json`;
		axios
			.get(APIURL)
			.then(function(response) {
				console.log(response, 'dsfsdfs');
				if (response.status == 200) {
					values = response.data ? response.data : '';
					console.log('values=', values.length);
					self.setState({
						data: values
					});
					// self.setState({
					// 	pageLayout: values
					// });
				}
			})
			.catch(function(error) {
				console.log(error);
			});
	};

	componentDidMount() {
		
		window.scrollTo(0, 0)
	}
	componentWillMount() {
		this.loadCategory();
	}
	render() {
		return (
			<div className='blogtopics'>
				{this.state.data != [] ? <h5>Blog Topics</h5> : ''}
				<ul className='catlisting'>
					{this.state.data.map((data) => (
						<li key={data.tid} className='catitem'>
							<Link
								to={{
					
									pathname:'/blog/'+data.tid,
									// pathname:"/blog?id="+data.name,
									state: {
										tid: data.tid ? data.tid : 'All',
										object_id:'/blog'
									}
								}}
								tid={data.tid}
								className='defaultlink'
							>
								{data.name}
							</Link>
						</li>
					))}
				</ul>
			</div>
		);
	}
}
export default BlogCategory;
