module.exports = {
	//API_URL: 'http://qudra.localhost/',
	//API_URL: 'http://php71.indianic.com/ibrainc2',
	//API_URL: 'https://ibrainc.devpress.net',
	API_URL: 'https://api.ibrainc.com',
	// API_URL: 'http://10.2.2.83/ibrainc2',
	//API_BASE_URL: 'http://202.131.112.60:83',
	//API_BASE_URL: 'http://php71.indianic.com/ibrainc2',
	//API_BASE_URL: 'http://php71.indianic.com/ibrainc2',
	API_BASE_URL: 'https://api.ibrainc.com',
	IMAGE_URL: '',
	SITE_URL: 'https://www.ibrainc.com/',
	HOME_ID: 169,
	//HEADER_MENU: 2,
	//  HEADER_MENU: 'main?_format=json',
	//  FOOTER_MENU: 'footer-navigation?_format=json',
	HEADER_MENU: 'home-page-header-menu?_format=json',
	FOOTER_MENU: 'footer-page-menu?_format=json',
	FOOTER_MENU_3: 5,
	PORT: 4204
	//API_BASE_URL: (serverip === '202.131.107.130')?'http://10.2.2.83':'http://202.131.112.60:83',
	//API_URL: (serverip === '202.131.107.130')?'http://10.2.2.83/ibrainc2':'http://202.131.112.60:83/ibrainc2',
};
