import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
class Track_package_module extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		//console.log(this.state);
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			//console.log('myobject', banner_image);		;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area'>
							<div className="tracking_package_list">
								{obj.field_track_your_package_heading && (
									<h2>{obj.field_track_your_package_heading.value}</h2>
								)}
								<br />
								<div className="d-flex  align-items-center courier_package_details_img">
									{obj.field_courier_package_details && ReactHtmlParser(obj.field_courier_package_details.processed)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Track_package_module;
