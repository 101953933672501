import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import LODINGIMG from './../../assets/images/IBRAInc-logo.png';

import { SITE_URL, FOOTER_MENU } from './../../config/config';

class Footer extends Component {
	constructor() {
		super();
		this.state = {
			footermenu: '',
			intervalId: 0
		};
	}

	componentDidMount() {
		//console.log("didmount at footer", this.props.headerdata2);
		var data = {
			menu_id: FOOTER_MENU
		};
		this.props.headerData(data, (response) => {
			//let { menu_1 } = response;
			this.setState({ footermenu: response.data });
			console.log('footer menu 1', this.state.footermenu);
		});
		var data1 = {};
		this.props.generalData(data1, (response) => {
			this.setState({ themeSetting: response.data });
			console.log('themeSetting', this.state.themeSetting);
		});
	}
	render() {
		// let general_data = '';
		// let footer_log = '';
		let footermenu = this.state.footermenu ? this.state.footermenu : null;
		let themeSettings = this.state.themeSetting ? this.state.themeSetting : '';
		// let menu_2 = this.state.menu_2;
		// let menu_3 = this.state.menu_3;

		//console.log('menu_1', footermenu);
		// if (this.props.headerdata2.data) {
		// 	general_data = this.props.headerdata2.data.data.acf;
		// 	footer_log = general_data.site_logo.url;
		// 	//console.log("general_data", general_data);
		// }
		return (
			<footer>
				{themeSettings.home_page_get_in_touch && (
					<div className='call-to-access'>
						<div className='container'>
							<div className='call-to-access-inner'>
								{themeSettings.home_page_get_in_touch.homepage_call_action_heading && (
									<h3>{themeSettings.home_page_get_in_touch.homepage_call_action_heading}</h3>
								)}
								{themeSettings.home_page_get_in_touch.homepage_call_action_details && (
									<p>{themeSettings.home_page_get_in_touch.homepage_call_action_details}</p>
								)}
								{themeSettings.home_page_get_in_touch.homepage_get_in_touch_readmore_title ||
								themeSettings.home_page_get_in_touch.homepage_get_in_touch_readmore_link ? (
									<Link
										to={{
											pathname:
												'/' +
												themeSettings.home_page_get_in_touch.homepage_get_in_touch_readmore_link.replace(
													'internal:/',
													''
												),
											state: {
												object_id: themeSettings.home_page_get_in_touch.homepage_get_in_touch_readmore_link.replace(
													'internal:/',
													''
												)
											}
										}}
										className='defaultbtn'
									>
										{themeSettings.home_page_get_in_touch.homepage_get_in_touch_readmore_title}
									</Link>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				)}

				<div className='footer-section'>
					<div className='container'>
						<div className='footer-top-inner row no-gutters'>
							<div className='col-md-12 col-lg-5'>
								<div className='contact-info'>
									<div className='footer_logo'>
										<img src={LODINGIMG} />
									</div>
									<p className='small'>Need an urgent freight service?</p>
									<p className='small'>Call our toll free number</p>
									<ul className='emil-tel'>
										{themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_phone && (
											<li>
												<a href='tel:+1 (617) 427-1010' className='tel'>
													{themeSettings.home_page_footer.homepage_footer_phone}
												</a>
											</li>
										)}
										{themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_email && (
											<li>
												<a href='mailto:sales@ibrainc.com' className='mail'>
													{themeSettings.home_page_footer.homepage_footer_email}
												</a>
											</li>
										)}
									</ul>
								</div>
							</div>
							<div className='col-md-12 col-lg-7'>
								<ul className='d-flex flex-column flex-md-row footer_menu'>
									{footermenu &&
										footermenu.map((page, i) => (
											
											<li
												key={i}
												onClick={() =>
													this.setState({
														mobileMenu: !this.state.mobileMenu
													})}
												className={
													page.sub_menu ? 'menu-item menu-item-has-children' : 'menu-item'
												}
											>
												<h5 className='menu_title'>{page.menu_name}</h5>
												{/* <Link
													to={{
														pathname: page.relative,
														state: {
															object_id: page.relative
														}
													}}
												>
													{page.title}
												</Link> */}
												
												<ul className='sub-menu'>
													{page.sub_menu &&
														page.sub_menu.map((item, i) => (
															<li key={i}>
																<Link
																	to={{
																		pathname: '' + item.link,
																		state: {
																			object_id: item.link
																		}
																	}}
																>
																	{item.menu_name}
																</Link>
															</li>
														))}
												</ul>
											</li>
										))}
								</ul>
							</div>
						</div>
						<div className='footer-bottom-inner d-flex flex-column flex-lg-row justify-content-between align-items-center'>
							<div className='footer-copy-right'>
								<ul className='d-flex flex-column flex-md-row align-items-center'>
									{themeSettings.home_page_footer &&
									themeSettings.home_page_footer.homepage_footer_copyright && (
										<li className='copyright-text'>
											<p>{themeSettings.home_page_footer.homepage_footer_copyright}</p>
										</li>
									)}
									{(themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_privacy_title) ||
									(themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_privacy_link) ? (
										<li>
											<Link
												to={{
													pathname:
														'/' +
														themeSettings.home_page_footer.homepage_footer_privacy_link.replace(
															'internal:/',
															''
														),
													state: {
														object_id: themeSettings.home_page_footer.homepage_footer_privacy_link.replace(
															'internal:/',
															''
														)
													}
												}}
											>
												{themeSettings.home_page_footer.homepage_footer_privacy_title}
											</Link>
										</li>
									) : (
										''
									)}
									{(themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_term_use_title) ||
									(themeSettings.home_page_footer &&
										themeSettings.home_page_footer.homepage_footer_term_use_link) ? (
										<li>
											<Link
												to={{
													pathname:
														'/' +
														themeSettings.home_page_footer.homepage_footer_term_use_link.replace(
															'internal:/',
															''
														),
													state: {
														object_id: themeSettings.home_page_footer.homepage_footer_term_use_link.replace(
															'internal:/',
															''
														)
													}
												}}
											>
												{themeSettings.home_page_footer.homepage_footer_term_use_title}
											</Link>
										</li>
									) : (
										''
									)}
								</ul>
							</div>
							<div className='footer-social'>
								<ul className='social-lists d-flex d-row align-items-center'>
									{themeSettings.home_page_footer &&
										(themeSettings.home_page_footer.homepage_footer_social_section &&
											themeSettings.home_page_footer.homepage_footer_social_section.map(
												(item, i) => (
													<li key={i} className='socail-link'>
														{(() => {
															switch (item.social_icon_title) {
																case 'Facebook':
																	return (
																		<a href={item.social_icon_link} target='_blank'>
																			<i className='fa fa-facebook-f' />
																		</a>
																	);
																case 'Twitter':
																	return (
																		<a href={item.social_icon_link} target='_blank'>
																			<i className='fa fa-twitter' />
																		</a>
																	);
																case 'Instagram':
																	return (
																		<a href={item.social_icon_link} target='_blank'>
																			<i className='fa fa-linkedin' />
																		</a>
																	);
																case 'Youtube':
																	return (
																		<a href={item.social_icon_link} target='_blank'>
																			<i className='fa fa-youtube-play' />
																		</a>
																	);
																default:
																	return (
																		<a href={item.social_icon_link} target='_blank'>
																			{item.social_icon_title}
																		</a>
																	);
															}
														})()}
													</li>
												)
											))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

const mapStateToProps = (state) => ({
	headerdata2: state.headerdata.headerDetails
});

export default connect(mapStateToProps, actions)(Footer);

//export default Footer;
