import { HEADER, GENERAL_DATA } from '../actions/types';
const INITIAL_STATE = {
	headerDetails: {},
	generalDetails: {}
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HEADER:
			return Object.assign({}, state, { headerDetails: action.payload });
		case GENERAL_DATA:
			return Object.assign({}, state, { generalDetails: action.payload });
		default:
			return INITIAL_STATE;
	}
};
