import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { API_URL } from './../../config/config';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Select from 'react-select';
import Spinner from './../../assets/images/spinner.gif';

import $ from 'jquery'
//import { Switch } from 'react-router-dom';

const emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

const MobileRegex = RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);

const formValid = ({ formErrors, ...rest }) => {
	let valid = true;

	// validate form errors being empty
	Object.values(formErrors).forEach((val) => {
		val.length > 0 && (valid = false);
	});

	// validate the form was filled out
	Object.values(rest).forEach((val) => {
		val === null && (valid = false);
	});

	return valid;
};

class Career_module extends Component {
	constructor(props) {
		super(props);
		this.state = {
			firstName: null,
			lastName: null,
			yourEmail: null,
			yourContact: null,
			yourMessage: null,
			formErrors: {
				firstName: '',
				lastName: '',
				yourEmail: '',
				yourContact: '',
				yourMessage: ''
			}
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	handleSubmit = (e) => {
		e.preventDefault();
		//console.log('this.state',this.state)
		$('.fromspinner').removeClass('d-none')
		if (formValid(this.state)) {
			let body = {
				webform_id: 'career',
				first_name: this.state.firstName,
				last_name: this.state.lastName,
				email: this.state.yourEmail,
				contact: this.state.yourContact,
				message: this.state.yourMessage
			};
			console.log('json=>', JSON.stringify(body));
			axios({
				method: 'post',
				url: API_URL + '/webform_rest/submit?_format=json',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
				},
				data: body
			}).then((response) => {
				let { data } = response;
				console.log('form submit responce', data.sid);
				if (data.sid !== '') {
					swal("Thank you for getting in touch, We will get back to you shortly.")
						.then((value) => {
							//document.getElementById("RequestQuoteFrom").reset();
							window.location.reload();
						});
				} else {
					swal('Something went wrong, please try again after sometime', '', 'error');
				}
				$('.fromspinner').addClass('d-none')
			});

		} else {
			console.error('FORM INVALID - DISPLAY ERROR MESSAGE');
			swal('Please fill all the require fields before submit a form', '', 'error');
			$('.fromspinner').addClass('d-none')
		}
	};

	handleChange = (e) => {
		e.preventDefault();
		//this.setState({ [e.target.name]: e.target.value });
		const { name, value } = e.target;
		//console.log(e.target,'target');
		let formErrors = { ...this.state.formErrors };
		//let formErrors = this.state.formErrors;

		switch (name) {
			case 'firstName':
				formErrors.firstName = value.length < 3 ? 'minimum 3 characaters required' : '';
				break;
			case 'lastName':
				formErrors.lastName = value.length < 3 ? 'minimum 3 characaters required' : '';
				break;
			case 'yourEmail':
				formErrors.yourEmail =
					value.length <= 0
						? 'email is required'
						: (formErrors.yourEmail = emailRegex.test(value) ? '' : 'invalid email address');
				break;
			case 'yourMobile':
				formErrors.yourMobile =
					value.length <= 0
						? 'mobile number required'
						: (formErrors.yourMobile = MobileRegex.test(value) ? '' : 'invalid mobile number');
				break;
			case 'yourPhone':
				formErrors.yourPhone =
					value.length <= 0
						? 'phone number required'
						: (formErrors.yourPhone = MobileRegex.test(value) ? '' : 'invalid phone number');
				break;
			case 'yourMessage':
				formErrors.yourMessage = value.length < 6 ? 'minimum 6 characaters required' : '';
				break;
			default:
				break;
		}

		this.setState({ formErrors, [name]: value }, () => console.log(this.state));
	};

	render() {
		//console.log(this.state);
		//let { yourName, yourEmail, yourMobile, yourPhone } = this.state;
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			//console.log('myobject', banner_image);		;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			const { formErrors } = this.state;
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area request_quote_module'>
							{obj.field_career_left_image && (
								<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content row'>
									<div className='left-part-content image-wpr col-lg-5 col-md-12'>
										<img
											src={obj.field_career_left_image.url}
											alt={obj.field_career_left_image.alt}
											title={obj.field_career_left_image.title}
										/>
									</div>
									<div className='right-part-content col-lg-7 col-md-12'>
										<div className='form_section'>
											{ReactHtmlParser(obj.field_career_detail.value)}
											<form
												id='myContactFrom'
												className='cv_form_inner'
												onSubmit={this.handleSubmit}
												noValidate
											>
												<div className='form-group row'>
													<div className='firstName form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.firstName.length > 0 ? (
																	'form-control error '
																) : (
																		'form-control'
																	)
															}
															name='firstName'
															placeholder='First Name'
															//onChange={this.handleChange}
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.firstName.length > 0 && (
															<span className='errorMessage'>{formErrors.firstName}</span>
														)}
													</div>
													<div className='lastName form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.lastName.length > 0 ? (
																	'form-control error '
																) : (
																		'form-control'
																	)
															}
															name='lastName'
															placeholder='Last Name'
															//onChange={this.handleChange}
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.lastName.length > 0 && (
															<span className='errorMessage'>{formErrors.lastName}</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourEmail form-item col-md-6 col-12'>
														<input
															type='email'
															className={
																formErrors.yourEmail.length > 0 ? (
																	'form-control error '
																) : (
																		'form-control'
																	)
															}
															name='yourEmail'
															placeholder='Email'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourEmail.length > 0 && (
															<span className='errorMessage'>{formErrors.yourEmail}</span>
														)}
													</div>

													<div className='yourContact form-item col-md-6 col-12'>
														<input
															type='text'
															className={
																formErrors.yourContact.length > 0 ? (
																	'form-control error '
																) : (
																		'form-control'
																	)
															}
															name='yourContact'
															placeholder='Contact'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourContact.length > 0 && (
															<span className='errorMessage'>
																{formErrors.yourContact}
															</span>
														)}
													</div>
												</div>
												<div className='form-group row'>
													<div className='yourMessage form-item col-12'>
														<textarea
															className={
																formErrors.yourMessage.length > 0 ? (
																	'form-control error '
																) : (
																		'form-control'
																	)
															}
															name='yourMessage'
															placeholder='Message'
															onBlur={this.handleChange}
															noValidate
														/>
														{formErrors.yourMessage.length > 0 && (
															<span className='errorMessage'>
																{formErrors.yourMessage}
															</span>
														)}
													</div>
												</div>

												<button type='submit' className='defaultbtn btn-yellow'>
													Submit
												</button>
												<img src={Spinner} className="fromspinner d-none" height="50" width="50" />
											</form>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Career_module;
