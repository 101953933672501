import '../../../node_modules/aos/dist/aos.css';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//import {Animated} from "react-animated-css";
import AOS from 'aos';
import { Link } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const settings = {
	dots: false,
	infinite: true,
	autoplay: false,
	speed: 800,
	slidesToShow: 1,
	slidesToScroll: 1
};
const servicesettings = {
	adaptiveHeight: false,
	variableWidth: false,
	dots: false,
	infinite: false,
	arrows: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
};
class Home_details extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		AOS.init();
	}
	next() {
		//AOS.refresh(true);
		this.slider.slickNext();
	}
	previous() {
		//AOS.refresh(true);
		this.slider.slickPrev();
	}
	componentWillReceiveProps() {
		//AOS.refresh();
	}
	// componentDidMount() {
	// 	const script = document.createElement('script');
	// 	script.src = './../../assets/js/slick-animation.min.js';
	// 	script.async = true;
	// 	script.onload = () => this.scriptLoaded();

	// 	document.body.appendChild(script);
	// }
	// scriptLoaded() {
	// 	console.log('dsfsdfsdf');
	// }

	render() {
		
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let home_slider = obj.home_page_slider ? Object.values(obj.home_page_slider) : '';
			let home_service = obj.home_page_services ? Object.values(obj.home_page_services) : '';
	
			let styles;
			console.log(home_slider,'test');
			return (
				<div className='page_innter_container'>
					{home_slider !== null ? (
						<Slider {...settings}>
							{Object.keys(home_slider).map((keyName, i) => (
								<div className='slick-slider-item' key={i}>
									<div
										className='slider-inner'
										data-aos='fade-zoom-in'
										data-aos-delay='300'
										style={{ backgroundImage: `url(${home_slider[i].slider_image})` }}
									>
										<div className='slider-content container'>
											<h2
												data-aos='zoom-in'
												data-aos-delay='500'
												data-aos-easing='ease-in-sine'
												data-aos-offset='0'
											>
												{home_slider[i].slider_title}
											</h2>
											<div
												data-aos='zoom-out-up'
												data-aos-delay='800'
												data-aos-easing='ease-in-sine'
												data-aos-offset='0'
											>
												{ReactHtmlParser(home_slider[i].slider_details)}
											</div>
											<div
												className='button-wpr'
												data-aos='zoom-out-up'
												data-aos-delay='1000'
												data-aos-easing='ease-in-sine'
												data-aos-offset='0'
											>
												<Link to='/about' className='defaultbtn btn-yellow'>
													{home_slider[i].slider_read_more_tilte}
												</Link>
												<Link to='/request-quote' className='defaultbtn btn-white'>
													{home_slider[i].slider_request_title}
												</Link>
											</div>
										</div>
										<div className='bggrident' />
									</div>
								</div>
							))}
						</Slider>
					) : (
						''
					)}
					{obj.home_page_about_intro && (
						<div className='home_about_intro'>
							<div className='container'>
								<h2 className='d-flex justify-content-center'>
									{obj.home_page_about_intro.about_ibra_top_heading}
								</h2>
								<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content no-gutters'>
									<div className='left-part-content col-xl-4 col-lg-5 col-md-12'>
										{obj.home_page_about_intro.about_ibra_details &&
											ReactHtmlParser(obj.home_page_about_intro.about_ibra_details)}
										{obj.home_page_about_intro.about_ibra_readmore_title &&
										obj.home_page_about_intro.about_ibra_readmore_link && (
											<Link												
												to={{
													pathname:
														'/' +
														obj.home_page_about_intro.about_ibra_readmore_link.replace(
															'internal:/',
															''
														),
													state: {
														object_id: obj.home_page_about_intro.about_ibra_readmore_link.replace(
															'internal:/',
															''
														)
													}
												}}
												className='defaultbtn btn-yellow'
											>
												{obj.home_page_about_intro.about_ibra_readmore_title}
											</Link>
										)}
										{obj.home_page_about_intro.about_ibra_bottom_image && (
											<div className='about-intro-left-img'>
												<img
													src={obj.home_page_about_intro.about_ibra_bottom_image}
													alt={obj.home_page_about_intro.about_ibra_top_heading}
												/>
											</div>
										)}
									</div>
									<div className='right-part-content image-wpr col-xl-7 col-lg-6 col-md-12'>
										{obj.home_page_about_intro.about_ibra_top_image && (
											<img
												src={obj.home_page_about_intro.about_ibra_top_image}
												alt={obj.home_page_about_intro.about_ibra_top_heading}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					)}
					{home_service !== null ? (
						<div className='service_slider_wpr'>
							<div className='container'>
								<div className='our_service_header'>
									<h2 className='d-flex justify-content-start justify-content-md-center'>
										Our Services
									</h2>
									<div style={{ textAlign: 'center' }} className='our_service_navigation'>
										<button className='button slick-prev' onClick={this.previous}>
											Previous
										</button>
										<button className='button slick-next' onClick={this.next}>
											Next
										</button>
									</div>
								</div>
							</div>
							<Slider ref={(c) => (this.slider = c)} {...servicesettings}>
								{Object.keys(home_service).map((keyName, i) => (
									
									<div className='slick-service-slider' key={i}>
											
										<div className='slider-inner'>
											<div className='slider-content container'>
												<img
													className='service_img'
													src={home_service[i].homepage_services_image}
													alt={home_service[i].homepage_services_name}
												/>
												<h4>{home_service[i].homepage_services_name}</h4>
												{ReactHtmlParser(home_service[i].homepage_services_details)}
												<div className='button-wpr'>
												
													<Link
														to={{
															pathname:
																'/' +
																home_service[i].homepage_services_readmore_link.replace(
																	'internal:/',
																	''
																),
															state: {
																object_id: home_service[
																	i
																].homepage_services_readmore_link.replace(
																	'internal:/',
																	''
																)
															}
														}}
														// to={home_service[i].homepage_services_readmore_link}
														className='defaultlink link-yellow with-arrow'
													>
														{home_service[i].homepage_services_readmore_title}
													</Link>
												</div>
											</div>
										</div>
									</div>
								))}
							</Slider>
						</div>
					) : (
						''
					)}
					{obj.home_page_real_time && (
						<div className='home_real_time'>
							<div className='container'>
								<h2 className='d-flex justify-content-center'>
									{obj.home_page_real_time.homepage_real_time_heding}
								</h2>
								{obj.home_page_real_time.homepage_real_time_subheding && (
									<div className='subheading_real_time row justify-content-center no-gutters'>
										<p className='col-lg-7 col-md-10'>
											{obj.home_page_real_time.homepage_real_time_subheding}
										</p>
									</div>
								)}
								<div className='row justify-content-center no-gutters'>
									{obj.home_page_real_time.homepage_real_time_details && (
										<div className='col-lg-5 col-md-8 col-sm-10 real-time-list-items'>
											{ReactHtmlParser(obj.home_page_real_time.homepage_real_time_details)}
											{obj.home_page_real_time.homepage_real_time_readmore_title && (
												<div className='button-wpr'>
													<Link
														to={{
															pathname:
																'/' +
																obj.home_page_real_time.homepage_real_time_readmore_link.replace(
																	'internal:/',
																	''
																),
															state: {
																object_id: obj.home_page_real_time.homepage_real_time_readmore_link.replace(
																	'internal:/',
																	''
																)
															}
														}}
														className='defaultbtn btn-white'
													>
														{obj.home_page_real_time.homepage_real_time_readmore_title}
													</Link>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			);
		}
	}
}

export default Home_details;
