import React, { Component } from 'react';
import axios from 'axios';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL, API_BASE_URL } from './../../config/config';
import { Link } from 'react-router-dom';


const  queryString  =  require('query-string')

class Blog_details_module extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
            blogDetails:[]
        };
	}

    componentDidMount(){
        
        // let  url1  =  this.props.getPropsData.location;
   }

    componentWillMount() {
		this.loadUser();
    }

    loadUser = () => {
        let url1 =  this.props.getPropsData.location.pathname
                // let  url1  =  this.props.getPropsData.location.view_node;
        // console.log('&&kk',url1.split('/').pop())
        var st = '/blog/'+url1.split('/').pop()
       console.log("st45",st)
		const { per, page, data } = this.state;
		let self = this;
		let values, mykey;
		let tid = this.state.tid;
		let APIURL = API_URL + st +`?_format=json`;
		axios({
			method: 'get',
			url: APIURL,
			headers: {
				'Content-Type': 'application/json',
				// Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
			}
		})
			.then(function(response) {
				if (response.status == 200) {
					values = response.data ? response.data : '';
					console.log('values=',response.data);
					self.setState({
						data:values,
						scrolling: false,
                        last_page: values.length < per ? true : false,
                        blogDetails:values? values:[]
						//total_pages: json.total_pages
					});
			
				}
			}) 
			.catch(function(error) {
				console.log(error);
			});
    };
    
    render() {
    let {blogDetails} = this.state
        let obj = blogDetails ? blogDetails : null;
		if (obj !== null ) {
            // let banner_image = obj.field_blog_node_background_banne ? obj.field_blog_node_background_banne : null;
            let banner_image = obj.field_blog_node_background_banne ? obj.field_blog_node_background_banne : null;
            let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
            let banner_image_url = banner_image && Array.isArray( banner_image)&&  banner_image.map((image, key) => { return ( image.url ? image.url : '' ) })
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			return (
                <div className='page_innter_container'>
                    {banner_image || banner_heading ? (
                        <div className='banner_section banner_inner_page'>
                            <div className='banner_main_section' style={styles.BannerImage}>
                                <div className='banner_content container'>
                                    { banner_heading && Array.isArray( banner_heading) && banner_heading.map((banner, key) => {
                                    return(
                                        <h2>{banner.value}</h2>
                                    )
                                    })
                                        } 
                                </div> 
                            <div className='bggrident' />
                            </div>
                        </div>
                    ) : null}
                    <div className="container">
                    <div className="bloglisting blog_detail_page">
                        <div className="breadcrumb bg-white">
                        <a href="/blog"><strong><b><i class="fa fa-long-arrow-left" aria-hidden="true"></i> Back to Blog page</b></strong></a>
                          
                        </div>
					    <div class="about_us_conaten col-md-12 col-lg-8 m-auto">
                            { blogDetails && Array.isArray( blogDetails.title) && blogDetails.title.map((title, key) => {
                            return(
                                <p ><b>{title.value}</b></p>    
                            )
                        })
                        } 
						 { blogDetails && Array.isArray( blogDetails.field_blog_date) &&blogDetails.field_blog_date.map((field_blog_date, key) => {
                            return(
                               <p className="bloddate">{field_blog_date.value}</p>
                            )
                        })
                        } 
					
						<div className="full_image mt-4">
                        { blogDetails && Array.isArray( blogDetails.field_image) &&blogDetails.field_image.map((field_image, key) => {
                                return(
                                    <img src={field_image.url}/> 
                                )
                            })
                        } 
						</div>
                        { blogDetails && Array.isArray( blogDetails.body) &&blogDetails.body.map((body, key) => {
                            return(
                               <p>{ReactHtmlParser(body.value)}</p>
                            )
                        })
                        } 
						<div className="row">
							<div className="col-12 m-auto">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="right-part-content image-wpr mt-4">
                                        { blogDetails && Array.isArray( blogDetails.field_blog_node_left_image) &&blogDetails.field_blog_node_left_image.map((left_image, key) => {
                                            return(
                                                <img src={left_image.url}/>
                                            )
                                        })
                                        } 
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<div className="right-part-content image-wpr mt-4">
                                        { blogDetails && Array.isArray( blogDetails.field_blog_node_right_image) &&blogDetails.field_blog_node_right_image.map((right_image, key) => {
                                            return(
                                                <img src={right_image.url}/>
                                            )
                                        })
                                        } 
										</div>
									</div>
								</div>
							</div>
						</div>
                        { blogDetails && Array.isArray( blogDetails.field_blog_node_bottom_content) && blogDetails.field_blog_node_bottom_content.map((bottom_content, key) => {
                                return(
                                    <p>{bottom_content.value}</p>
                                )
                                })
                            } 
					</div>
				</div>
			
                </div>
			</div>
			
			);
		 }
	}
} 
export default Blog_details_module;