import React from 'react';
import NoResult from './../../assets/images/no_result_found.png';
class No_result extends  React.Component {
    state = {  }
    render() { 
        return ( 
            <div className='page_innter_container'>
                <div className="container">
                    <div className="search-blog">
                        <div className="search-text">
                          
                        </div>
                        <div className="search-results">
                            <h2>No results</h2>
                        </div>
                        <div className="serch-form">
                            <img src={NoResult} />
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default No_result;