/* eslint-disable no-unused-expressions */
import React from 'react';
import axios from 'axios';
import { API_URL } from './../config/config';
import Home_details from './modules/Home_details';
import About_us_module from './modules/About_us_module';
import Contact_us_module from './modules/Contact_us_module';
import Request_quote_module from './modules/Request_quote_module';
import Career_module from './modules/Career_module';
import Service_details_module from './modules/Service_details_module';
import Track_package_module from './modules/Track_package_module';
import Technology_details_module from './modules/Technology_details_module';
import Blog_module from './modules/Blog_module';
import Blog_details_module from './modules/Blog_details_module';
import queryString from 'query-string';
import Search_details from './modules/Search_details';
import No_result from './modules/No_result';
import Privacy_policy from './modules/Privacy_policy';
import Terms_use from './modules/Terms_use'
//import $ from "jquery";
import { Helmet } from 'react-helmet';
class Contentpart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLayout: [],
			object_id: 'home'
			//'moveTo: props.location.state ? props.location.state.section_name : null,
			//seoTags: []
		};

		this.getBlockComponent = this.getBlockComponent.bind(this);
		//this.updatePageSection = this.updatePageSection.bind(this);
	}

	componentWillMount() {
		//let moveToId = this.state.moveTo;
		//console.log("object_id@@", this.state.object_id);
		//console.log("moveTo@@", moveToId);
		//if (moveToId !== null && this.state.object_id == "") {
		// if (moveToId) {
		// 	this.updatePageSection(this.state.moveTo);
		// }
	}

	componentDidMount() {
		
		document.body.classList.add('inner_page_body');
	    // document.body.classList.add('search_body');
		// console.log("location",this.props)
		//document.('.main_menu_main').classList.add("inner_page_body");
		const parsed = queryString.parse(this.props.location.search);
		// console.log('parsed', parsed);
		try {
			//console.log(window.location.pathname, 'pathname');
			if (window.location.pathname == '/' || window.location.pathname == '') {
				this.setState({ object_id: 'home' }, function() {
					this.updatePageID(this.state.object_id);
				});
			} else {
				
				let objectslist = window.location.pathname.split('/');
			
				let Sateobject_id = this.state.object_id;
				if (objectslist.length > 2) {
					let Sateobjectobj = objectslist[1];
					Sateobjectobj == 'blog' ? (Sateobject_id = '/blog') : '';
				}
				this.setState({ object_id: window.location.pathname }, function() {
					this.updatePageID(Sateobject_id);
				});
			}
		} catch (err) {
			console.log(err, '@');
		}
	}
	updatePageID(e) {
		if (this.state.object_id) {
			let self = this; 		//Once inside the axios request, this will not be the same this.
			let values, mykey;
			var myObj = [];
			// let objectslist = this.state.object_id.split("/");
			// let APIName = this.state.object_id;
			// if(objectslist.length > 2){
			// 	let APIName = objectslist[2];
			// }
			let Currentpath = this.state.object_id;
			let objectslist = Currentpath.split('/');
			if (objectslist.length > 2) {
				Currentpath = objectslist[1];
				Currentpath == 'blog'
					? self.setState({
							object_id: '/blog'
						})
					: '';
			}
			let CurrentObjectId = Currentpath == 'blog' ? '/' + Currentpath : this.state.object_id;
			let APIURL = API_URL + CurrentObjectId + '?_format=json';
			if (this.state.object_id == 'home' || this.state.object_id == '/') {
				APIURL = API_URL + '/home-page/get.json';
				Currentpath = 'home';
				self.setState({
					object_id: 'home'
				});
			}			
			axios({
				method: 'get',
				url: APIURL,
				headers: {
					'Content-Type': 'application/json',
					//Authorization: 'Basic aWJyYWluYzI6aWJyYWluYzI='
				}
			})
				.then(function(response) {
					if (response.status == 200) {
						if (Currentpath !== 'home') {
							Object.keys(response.data).map((key) => {
								mykey = key ? key : '';
								values = '';
								Object.keys(response.data[key]).map((d) => {
									values = response.data[key][d] ? response.data[key][d] : '';
								});
								myObj[mykey] = values;
							});
							self.setState({
								pageLayout: myObj
							});
							//console.log(myObj, 'myObj');
						} else {
							values = response.data ? response.data : '';
							self.setState({
								pageLayout: values
							});
						}
					}
				})
				.catch(function(error) {
					console.log(error);
				});
		}
	}

	componentWillReceiveProps(nextProps) {
		//this.setState({ moveTo: nextProps.location.state.section_name });
		console.log('object_idobject_id', this.state.object_id);

		if (this.state.object_id !== null) {
			this.setState({ object_id: nextProps.location.pathname }, () => this.updatePageID(this.state.object_id));
		}
		this.setState({ homeInnerClass: 'header_main inner_page' });
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}

	getBlockComponent(layout) {
		let objectslist = layout.object_id.split('/');
		let PageNameLayout = layout.object_id;
		let blogDetails = '';
		if (objectslist.length > 2) {
			PageNameLayout = objectslist[1] + 'details';
		}
		
		if(layout.object_id.includes('/blogs/')){
			PageNameLayout = layout.object_id
			blogDetails = layout.object_id
		}
		
		//let pageLayout = layout.pageLayout ? Object.values(layout.pageLayout) : null;
		
		switch (PageNameLayout) {
			case 'home':
				return <Home_details moduledata={layout.pageLayout} />;
			case '/about':
				return <About_us_module moduledata={layout.pageLayout} />;
			case '/request-quote':
				return <Request_quote_module moduledata={layout.pageLayout} />;
			case '/contact-us':
				return <Contact_us_module moduledata={layout.pageLayout} />;
			case '/career':
				return <Career_module moduledata={layout.pageLayout} />;
			case '/track-your-package':
				return <Track_package_module moduledata={layout.pageLayout} />;
			case 'servicedetails':{console.log("**",layout.pageLayout)}
				return <Service_details_module moduledata={layout.pageLayout} />;
			case 'technologydetails':
				return <Technology_details_module moduledata={layout.pageLayout} />;
			case '/blog':
				return <Blog_module moduledata={layout.pageLayout} />;
			case blogDetails:
			return <Blog_details_module moduledata={layout.pageLayout} getPropsData={this.props}/>;
			case '/search_details': 
				return <Search_details moduledata={layout.pageLayout} getPropsData={this.props}/>;
			case '/no_result':
				return <No_result moduledata={layout.pageLayout} />;
			case '/privacy-policy': 
				return <Privacy_policy moduledata={layout.pageLayout} />;
			case '/terms-use':
				return <Terms_use moduledata={layout.pageLayout} />;
			default:
				
				return '';
		}
		//return block.acf_fc_layout;
	}

	render() {
		let pageLayout = this.state.pageLayout ? this.state.pageLayout : null;
		let seoTagListing = pageLayout.metatag ? pageLayout.metatag : '';
		let layoutName = this.state.object_id;
		//let seoTagsList = this.state.seoTags;
		//console.log('pageLayout this.state ==>', seoTagListing.title);
		//let boxClass = [ 'header_main' ];
		//boxClass.push('inner_page');
		return (
			<div>
				<Helmet>
					{seoTagListing.title ? <title>{seoTagListing.title}</title> : 'welcome'}
					{seoTagListing.description && <meta name='description' content={seoTagListing.description} />}
					{/* { seoTagListing &&  (
						seoTagListing.title ? <title>{seoTagListing.title}</title> : ''
						seoTagListing.description ? <meta name='description' content={seoTagListing.description} /> : ''
					)}					 */}
				</Helmet>
				<div className='main_contanier'>
					{this.getBlockComponent(this.state)}
					{/* {pageLayout.map((layout, i) => <div key={i}>{this.getBlockComponent(layout)}</div>)} */}
				</div> 
			</div>
		);
	}
}

export default Contentpart;
