import React from 'react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

class Service_details_module extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render() {
		let obj = this.props.moduledata ? this.props.moduledata : null;
		if (obj !== null) {
			let banner_image = obj.field_background_banner_image ? obj.field_background_banner_image : null;
			let banner_heading = obj.field_banner_heading ? obj.field_banner_heading : null;
			let banner_image_url = banner_image && banner_image.url ? banner_image.url : '';
			const styles = {
				BannerImage: {
					backgroundImage: `url(${banner_image_url})`
				}
			};
			return (
				<div className='page_innter_container'>
					{banner_image || banner_heading ? (
						<div className='banner_section banner_inner_page'>
							<div className='banner_main_section' style={styles.BannerImage}>
								<div className='banner_content container'>
									<h2>{banner_heading.value}</h2>
								</div>
								<div className='bggrident' />
							</div>
						</div>
					) : null}
					<div className='container'>
						<div className='page_main_area service_inner_content'>
							{obj.field_services_right || obj.field_services_left_image ? (
								<div className='d-flex justify-content-between flex-column flex-lg-row two-part-content no-gutters'>
									<div className='left-part-content col-lg-5 col-md-12'>
										{ReactHtmlParser(obj.field_services_right.value)}
									</div>
									<div className='right-part-content image-wpr col-lg-6 col-md-12'>
										<img
											src={obj.field_services_left_image.url}
											alt={obj.field_services_left_image.alt}
											title={obj.field_services_left_image.title}
										/>
									</div>
								</div>
							) : (
									''
								)}
						</div>
					</div>
				</div>
			);
		}
	}
}

export default Service_details_module;
