import $ from 'jquery';
var ibra = ibra || {};
var debug = debug || {
	log: function(e) {}
};

ibra.customjs = {
	allLinks: null,
	submenu: null,
	init: function() {
		//$(document).on('click', '.main_menu .menu-item-has-children > a', ibra.customjs.MainMenuAction);
		// $(document).on('click', '#nav-icon2', function() {
		// 	$(this).toggleClass('open');
		// });
		if ($('.main_menu').length > 0) {
			$(document).on('mouseup', 'body', function(e) {
				if ($(e.target).closest('.sub-menu').length === 0) {
					$('.menu-item-has-children.active > a').trigger('click');
				}
			});
		}

		// if ($('#mySidenav').length > 0) {
		//     $('#mySidenav').mouseup(function (e) {
		//         if ($(e.target).closest(".mobile-menu").length === 0 && $(e.target).closest(".menuclosebtn").length === 0) {
		//             $('.menuclosebtn').trigger( "click" );
		//         }
		//     });
		// }

		/*$('.search-toggle').on('click', aloq.menu.mobilesearch);*/
	},
	MainMenuAction: function(e) {
		e.preventDefault();
		$(this).toggleClass('active').parent('li').toggleClass('active');
		$(this)
			.siblings('.sub-menu')
			.stop()
			.slideToggle('fast', function() {
				if (!$(this).is(':visible')) {
					$(this).removeClass('active');
				}
			})
			.toggleClass('active');
	}
};
$(document).ready(ibra.customjs.init);
